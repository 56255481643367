import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'
import Markdown from '../markdown'
import theme from '../../theme'

const Footnote = ({ content }) => {
  return (
    <Box pt={[3, 3, 3, 4, 4]}>
      <Markdown
        textColor={theme.text.color}
        content={content}
        pmt={[2, 2, 2, 2, 2]}
        pmb={[2, 2, 2, 2, 2]}
      />
    </Box>
  )
}

Footnote.propTypes = {
  content: PropTypes.string,
}

export default Footnote
