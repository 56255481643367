import React from 'react'
import { Flex, Box } from 'theme-ui'

const FootnoteDivider = () => {
  return (
    <Flex sx={{ alignItems: 'center', justifyContent: 'center' }}>
      <Box
        as="li"
        sx={{
          fontSize: [6, 6, 6, 6, 6],
        }}
      />
      <Box
        as="li"
        sx={{
          fontSize: [6, 6, 6, 6, 6],
        }}
      />
      <Box
        as="li"
        sx={{
          fontSize: [6, 6, 6, 6, 6],
        }}
      />
      <Box
        as="li"
        sx={{
          fontSize: [6, 6, 6, 6, 6],
        }}
      />
    </Flex>
  )
}

export default FootnoteDivider
