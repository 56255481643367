import React from 'react'
import PropTypes from 'prop-types'
import { Text, Box } from 'theme-ui'
import styled from 'styled-components'
import colors from '../../theme/colors'
import theme from '../../theme'

const ReportsMenu = ({ subsections, handleClick, menuItemColors }) => (
  <Box as="ul" my={1} pl={[3, 4]}>
    {subsections.map(
      (subsection, index) =>
        subsection.subsectionTitle && (
          <Box
            as="li"
            sx={{ color: menuItemColors[index], fontSize: [6, 6, 6, 6, 6] }}
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link onClick={(event) => handleClick(event, index)}>
              <Text
                sx={{
                  variants: 'styles.a',
                  fontSize: [3, 3, 3, 3, 3],
                  fontWeight: 'normal',
                  lineHeight: 1.2,
                  color: theme.nav.textColor,
                  ':hover': {
                    transition: 'all .3s ease-out',
                    boxShadow: `0 -1px 0 0 ${menuItemColors[index]} inset`,
                    width: 'fit-content',
                  },
                }}
              >
                {subsection.subsectionTitle}
              </Text>
            </Link>
          </Box>
        )
    )}
  </Box>
)

const Link = styled.div`
  cursor: pointer;
  color: ${colors.grey['50']};
`

ReportsMenu.propTypes = {
  subsections: PropTypes.arrayOf(
    PropTypes.shape({
      subsectionTitle: PropTypes.string,
      subsectionContent: PropTypes.string,
    })
  ),
  handleClick: PropTypes.func,
  menuItemColors: PropTypes.arrayOf(PropTypes.string),
}
export default ReportsMenu
