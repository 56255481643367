import React, { useRef, createRef } from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import { Container, Text, Box, Flex, Link } from 'theme-ui'
import Layout from '../components/Layout'
import Markdown from '../components/markdown'
import ReportsMenu from '../components/reportsMenu'
import Footnote from '../components/footnote'
import FootnoteDivider from '../components/footnote-divider'
import theme from '../theme'
import reportPdf from '../pdf/Report.pdf'

const menuItemColors = [
  `#FF0018`,
  `#FFA52C`,
  `#FFFF41`,
  `#1FC600`,
  `#2A9DF4`,
  `#A257E8`,
  `#FF0080`,
  `#3FC1BE`,
  `#964B00`,
  `#FF0018`,
  `#FFA52C`,
  `#FFFF41`,
  `#1FC600`,
  `#2A9DF4`,
  `#A257E8`,
  `#FF0080`,
  `#3FC1BE`,
  `#964B00`,
]

export const ReportPageTemplate = ({
  title,
  intro,
  reportContent,
  footnote,
}) => {
  const subsections = reportContent.subsections || []
  const refs = useRef(subsections.map(() => createRef()))
  const handleClick = (event, index) => {
    refs.current[index].current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  return (
    <>
      <Box
        bg={theme.nav.backgroundColor}
        sx={{
          borderTop: '1px solid #d2cec6',
          borderBottom: '1px solid #d2cec6',
        }}
      >
        <Container pt={[4, 5]}>
          {title && (
            <Markdown
              textColor={theme.nav.textColor}
              content={title}
              h1mb={[1, 1, 1, 1, 1]}
            />
          )}
          {title && (
            <Markdown
              textColor={theme.intro.textColor}
              content={intro}
              pFontSize={[1, 1, 1, 1, 1]}
              pmt={[2, 2, 2, 3, 3]}
              pmb={[0, 0, 0, 0, 0]}
            />
          )}
          {subsections && (
            <Box pt={[4, 4, 4, 4, 4]} pb={[5, 5, 5, 5, 5]}>
              <ReportsMenu
                subsections={subsections}
                handleClick={handleClick}
                menuItemColors={menuItemColors}
              />
            </Box>
          )}
        </Container>
      </Box>

      <Box>
        {subsections && (
          <>
            {subsections.map((subsection, index) => (
              <>
                <Box ref={refs.current[index]}>
                  {subsection.subsectionTitle && (
                    <Box py={[3, 3, 3, 4, 4]} bg={theme.titles.backgroundColor}>
                      <Container>
                        <Text
                          as="h2"
                          color={menuItemColors[index]}
                          sx={{
                            variant: 'styles.h2',
                          }}
                        >
                          {subsection.subsectionTitle}
                        </Text>
                      </Container>
                    </Box>
                  )}
                  <Container>
                    {subsection.subsectionContent && (
                      <Box py={[3, 3, 3, 4, 4]}>
                        <Markdown
                          textColor={theme.text.color}
                          content={subsection.subsectionContent}
                          pmb={[2, 2, 2, 2, 2]}
                        />
                      </Box>
                    )}
                  </Container>
                </Box>
              </>
            ))}
          </>
        )}
      </Box>
      {footnote && (
        <Box pb={[3, 3, 3, 4, 4]}>
          <Container>
            <FootnoteDivider />
            <Footnote content={footnote} />
          </Container>
        </Box>
      )}
      <Box mt={[3, 3, 3, 4, 4]} mb={[5, 5, 5, 5, 5]} pr={[4, 4, 4, 5, 5]}>
        <Container>
          <Flex sx={{ justifyContent: 'flex-end' }}>
            <Link href={reportPdf} download>
              <Text>Download PDF</Text>
            </Link>
          </Flex>
        </Container>
      </Box>
    </>
  )
}

ReportPageTemplate.propTypes = {
  title: PropTypes.string,
  intro: PropTypes.string,
  reportContent: PropTypes.shape({
    subsections: PropTypes.arrayOf(
      PropTypes.shape({
        subsectionTitle: PropTypes.string,
        subsectionContent: PropTypes.string,
      })
    ),
  }),
  footnote: PropTypes.string,
}

const ReportPage = ({
  data: {
    markdownRemark: { frontmatter: data },
  },
}) => (
  <Layout>
    <ReportPageTemplate {...data} />
  </Layout>
)

ReportPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        data: PropTypes.shape({}),
      }),
    }).isRequired,
  }).isRequired,
}

// export default ReportPage

export default () => (
  <StaticQuery
    query={graphql`
      query ReportPageQuery {
        markdownRemark(frontmatter: { templateKey: { eq: "report-page" } }) {
          frontmatter {
            title
            intro
            reportContent {
              subsections {
                subsectionContent
                subsectionTitle
              }
            }
            footnote
          }
        }
      }
    `}
    render={(data) => <ReportPage data={data} />}
  />
)
